export const routes = [
    // 我的
    {
        name: 'me',
        path: '/me/:student_user_id(\\d+)?',
        // redirect: '/me/:student_user_id(\\d+)?/study/course',
        redirect: '/me/:student_user_id(\\d+)?/study/meBill',
        meta:{title:'我的',login:true},
        props:true,
        component: () => import('@/views/me'),
        // 我的学习
        children: [
            { name: 'meStudy', path: "study",meta:{title:'我的学习'}, component: () => import('@/views/me/study.vue'), children:[
                    { name: 'meBill', path: "meBill",meta:{title:'我的账单'}, component: () => import('@/views/me/components/MeBill.vue')},
                    { name: 'meMessage', path: "meMessage",meta:{title:'我的消息'}, component: () => import('@/views/me/components/MeMessage.vue'), children:[
                        { name: 'meMessageSystem', path: "system",meta:{title:'系统消息'},props:{type:1}, component: () => import('@/views/me/components/message/system.vue'), },
                        { name: 'meMessageInteract', path: "interact",meta:{title:'互动消息'},props:{type:2}, component: () => import('@/views/me/components/message/system.vue'), },
                        { name: 'meMessageApply', path: "apply",meta:{title:'申请记录'}, props:{type:3},component: () => import('@/views/me/components/message/apply.vue'), },
                        { name: 'meMessageCourse', path: "course",meta:{title:'班课审核管理'}, props:{type:4},component: () => import('@/views/me/components/message/course.vue'), },
                            ]
                    },
                    { name: 'meStudyCourse', path: "course",meta:{title:'我的课程'}, component: () => import('@/views/me/components/MeCourse.vue'), },
                    { name: 'meStudyQuestList', path: "questList",meta:{title:'我的题单'}, component: () => import('@/views/me/components/MeForm.vue'), },
                    { name: 'meStudyQuestion', path: "question",meta:{title:'我的题目'}, component: () => import('@/views/me/components/MeSubject.vue'), },
                    { name: 'meStudyQuestother', path: "questother",meta:{title:'我的比赛&考试&作业'}, component: () => import('@/views/me/components/MeTask.vue'), },
                    { name: 'meStudyTeam', path: "team",meta:{title:'我的团队'}, component: () => import('@/views/me/components/MeTeam.vue'), },
                    { name: 'meStudyForum', path: "forum",meta:{title:'我的论坛'}, component: () => import('@/views/me/components/MeForum.vue'), },
                    { name: 'meStudyCollet', path: "collet",meta:{title:'我的收藏'},redirect: '/me/study/collet/course', component: () => import('@/views/me/components/MeCollect.vue'), children:[
                            { name: 'meStudyColletCourse', path: "course",meta:{title:'课程'}, component: () => import('@/views/me/components/MeCourse.vue'), },
                            { name: 'meStudyColletQuestList', path: "questList",meta:{title:'题单'}, component: () => import('@/views/me/components/MeForm.vue'), },
                            { name: 'meStudyColletQuestion', path: "question",meta:{title:'题目'}, component: () => import('@/views/me/components/MeSubject.vue'), },
                            { name: 'meStudyColletForum', path: "forum",meta:{title:'论坛'}, component: () => import('@/views/me/components/MeForum.vue'), },
                            { name: 'meStudyColletCoursePlan', path: "coursePlan",meta:{title:'教案'}, component: () => import('@/views/me/components/MeCourse.vue'), },
                        ]
                    },
                    { name: 'meStudyOrder', path: "order",meta:{title:'我的订单'}, component: () => import('@/views/me/components/MeOrder.vue'), },
                ]
            },
            // { name: 'meMessage', path: "meMessage",meta:{title:'我的消息'},redirect: '/me/meMessage/system', component: () => import('@/views/me/components/MeMessage.vue'), children:[
            //         { name: 'meMessageSystem', path: "system",meta:{title:'系统消息'},props:{type:1}, component: () => import('@/views/me/components/message/system.vue'), },
            //         { name: 'meMessageInteract', path: "interact",meta:{title:'互动消息'},props:{type:2}, component: () => import('@/views/me/components/message/system.vue'), },
            //         { name: 'meMessageApply', path: "apply",meta:{title:'申请记录'}, component: () => import('@/views/me/components/message/apply.vue'), },
            //         { name: 'meMessageCourse', path: "course",meta:{title:'班课审核管理'}, component: () => import('@/views/me/components/message/course.vue'), },
            //     ]
            // },
            { name: 'meBill', path: "meBill",meta:{title:'我的账单'}, component: () => import('@/views/me/components/MeBill.vue')},
            { name: 'meFeedback', path: "meFeedback",meta:{title:'我的反馈'}, component: () => import('@/views/me/components/MeFeedback.vue'), },
            { name: 'meFeedbackDetaild', path: "meFeedbackDetaild",meta:{title:'我的反馈详情'}, component: () => import('@/views/me/components/MeFeedbackDetaild.vue'), },
            { name: 'meMessageDetaild', path: "meMessageDetaild",meta:{title:'消息详情'}, component: () => import('@/views/me/components/MeFeedbackDetaild.vue'), },
        ]

    },
    //我的付款
    {
        name: '我的付款',
        path: "/me/courseDetails/paymentStatus",
        meta:{title:'我的付款',login:true},
        component: () => import('@/views/me/PaymentStatus.vue'),
    },
    { name: 'applyTeach', path: '/me/applyTeach', meta:{title:'申请成为教师'}, component: {
            template: '<div style="margin-top: 20px;"><el-card style="background-color: #fff;"><div slot="header" class="clearfix"><span>申请成为教师</span></div><teach-register type="apply"></teach-register></el-card></div>',
            components:{
                teachRegister:()=>{return import('@/components/SignRegister/teachRegister')},
            },
        },
    },
    //编辑个人资料
    {
        name: 'meInfo',
        path: "/me/editData",
        meta:{title:'编辑资料',login:true},
        component: () => import('@/views/me/EditData.vue'),
        children: [
            { name: 'meInfoPersonalData', path: "/me/personalData", meta:{title:'个人资料'}, component: () => import('@/views/me/personalData/PersonalData.vue'), },
            { name: 'meInfoAccount', path: "/me/account", meta:{title:'安全账户'}, component: () => import('@/views/me/personalData/Account.vue'), },
            { name: 'meInfoEditTeach', path: '/me/editTeach', meta:{title:'教师资料'}, component: {
                    template: '<el-card><div slot="header" class="clearfix"><span>教师资料</span></div><teach-register></teach-register></el-card>',
                    components:{
                        teachRegister:()=>{return import('@/components/SignRegister/teachRegister')},
                    },
                },
            },
        ]
    },
    {
        name: 'meCourseSpeedComment',
        path: "/me/comment",
        meta:{title:'结果评论',login:true},
        component: () => import('@/views/me/comment.vue'),
    },
    {
        name: 'meCourseSpeed',
        path: "/me/speed/:study_user_id(\\d+)?",
        meta:{title:'学习进度',login:true},
        component: () => import('@/views/me/speed.vue'),
    },
]